@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&family=Teko:wght@500&display=swap');
/* import bootstrap */
/* @import '~bootstrap/dist/css/bootstrap.min.css'; */

body {
  font-family: 'Rubik', sans-serif;
  margin: 0;
}

.flex {
  display: flex;
}