.modal-bg {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    opacity: 1;
    animation: fadeIn 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
}

.modal-container {
    width: 40%;
    height: fit-content;
    padding: 4rem 0;
    border-radius: 0.7rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 100;
}

.modal-close-btn{
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    width: 1.5rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: none;
    background-color: rgb(101, 99, 99);
    color: white;
    cursor: pointer;
    z-index: 100;
    transition: background-color 0.1s ease;
    padding: 0;
    padding-top: 0.1rem;
}
.modal-close-btn:hover{
    background-color: rgb(230, 26, 26);
    transition: background-color 0.2s ease;
}


/* Form */
.reg-link{
    color: rgb(102, 98, 98);
    cursor: pointer;
    transition: color 0.1s ease;
    text-decoration: underline;
}
.reg-link:hover{
    color: #eb8334;
    transition: color 0.2s ease;
}


@media screen and (max-width: 768px) {
    .modal-container {
        width: 95%;
    }
    .register-form {
        padding: 0 1rem;
    }
}