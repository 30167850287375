#store-page {
    background-color: #EEF0F3;
    min-height: 100vh;
}

/* Product */
.store-content {
    margin-left: 5%;
    margin-top: 3rem;
    /* display: flex;
    justify-content: center; */
}

.products {
    border: 1px solid rgb(240, 228, 228);
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 10px;
}

.product {
    width: 12rem;
    border-radius: 0.5rem;
    padding-bottom: 0.5rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    /* margin: 0 1.5rem 2rem; */
}

.product-img {
    width: 100%;
    aspect-ratio: 1/1;
    background-image: url("../../../public/no-image.png");
    background-size: cover;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    margin-bottom: 0.2rem;
    position: relative;
}

.product-offer {
    background-color: #f08214f0;
    width: fit-content;
    height: 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.1rem 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    color: white;
}
.product-initialPrice {
    background-color: yellow;
    width: fit-content;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.1rem 0.5rem;
    border-start-end-radius: 0.5rem;
    text-decoration: line-through;
    color: gray
}
.product-price {
    background-color: yellow;
    width: fit-content;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0.1rem 0.5rem;
    border-start-start-radius: 0.5rem;
}

.product-name {
    font-size: 1.1rem;
    margin-top: 0.3rem;
    margin-bottom: 0.4rem;
}

.store-btn {
    all: unset;
    width: 5.5rem;
    height: 2rem;
    background-color: #F08214;
    /* background: linear-gradient(0deg, rgb(236, 119, 3) 0%, rgba(240, 130, 20, 1) 35%, rgba(250, 158, 65, 1) 100%); */
    border-radius: 0.3rem;
    text-align: center;
    color: white;
    font-size: 0.9rem;
    transition: 0.1s;
}

.store-btn:hover {
    background-color: #d97006;
    transition: 0.2s;
    cursor: pointer;
}

.know-btn {
    margin-left: 0.5rem;
    background-color: rgb(190, 176, 128);
}

.know-btn:hover {
    background-color: rgb(170, 156, 108);
    transition: 0.2s;
}

/* Cart */
.cart-area {
    background-color: #fff;
    margin-left: 2%;
    margin-right: 1%;
    border: 1px solid rgb(240, 228, 228);
    width: 20rem;
    border-radius: 0.5rem;
    padding: 1rem;
    height: fit-content;
    position: sticky;
    top: 1rem;
    right: 0;
}

.product-card {
    background-color: #FFF5E8;
    border: 1px solid gray;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

.product-card-img {
    background-color: #fae846;
    background-size: cover;
    width: 4rem;
    aspect-ratio: 1/1;
    border-top-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.product-card-right {
    margin: auto 1rem;
}

.product-card-price {
    background-color: rgb(189, 184, 184);
    padding: 0.1rem 0.5rem;
    border-radius: 1rem;
    /* width: fit-content; */
}

.product-card-quantity {
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
    padding: 0.2rem 0.5rem;
    background-color: #F08214;
    border-radius: 1rem;
}

.product-card-input {
    width: 2rem;
    text-align: center;
    border: none;
    background-color: #F08214;
    color: white;
    font-weight: 700;
}

.product-card-quantity-btn {
    all: unset;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #8d4701;
    border-radius: 0.6rem;
    color: white;
    font-weight: 700;
    margin-right: 0.3rem;
    text-align: center;
    width: 1.4rem;
    height: 1.4rem;
    transition: background-color 0.1s;
}

.product-card-quantity-btn:hover {
    background-color: #6d3a01;
    transition: background-color 0.2s;
    cursor: pointer;
}

.product-card-name {
    margin-top: 0.3rem;
    margin-bottom: 0.4rem;
    font-weight: 700;
    padding-left: 0.2rem;
}

.cart-table,
.cart-th,
.cart-td {
    border: 2px solid;
    border-style: dashed;
    border-collapse: collapse;
}

.cart-date {
    margin-bottom: 0.5rem;
}

.cart-total {
    margin-top: 2rem;
    font-weight: 400;
    margin-bottom: 5rem;
}

#cart-price {
    font-weight: 700;
}

.cart-btn {
    position: absolute;
    bottom: 0;
    left: 0;
}


@media screen and (max-width: 768px) {
    .store-content {
        display: unset;
        margin-left: 0;
        margin-top: 0;
    }

    .cart-area {
        width: unset;
        border: none;
        margin: unset;
    }

    .products {
        grid-template-columns: repeat(2, 1fr);
        padding: 0.1rem;
    }

    /* .products:nth-child(odd){
        margin-left: 0;
    }
    .products:nth-child(even){
        margin-right: 0;
    } */
    .product {
        width: 10rem;
        margin: 0.2rem;
    }

    .cart-bottom-bar {
        /* background-color: purple; */
        position: fixed;
        bottom: 0;
        width: 100%;
        /* height is defined in inline style */
        /* border-radius: 0.5rem; */
        overflow-y: scroll;
        transition: height 0.5s;
        z-index: 10;
    }

    .items-mini-view {
        position: fixed;
        z-index: 10;
        width: 100%;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 1rem;
        height: 3.5rem;
        background: linear-gradient(0deg, rgb(210, 208, 202) 0%, rgba(135, 131, 122, 1) 100%);
        /* position: fixed; */
        /* top: 0; */
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
    }

    .product-bubble {
        height: 80%;
        aspect-ratio: 1/1;
        background-image: url("../../../public/no-image.png");
        background-size: cover;
        margin-right: 0.7rem;
        border-radius: 50%;
    }

    .cart-area {
        margin-top: 3.4rem;
    }

    .cart-expand-btn {
        margin-left: auto;
        margin-right: 1.5rem;
        background-color: #fff;
        padding: 0.5rem;
        border-radius: 0.5rem;
    }

    .products {
        margin-bottom: 2.5rem;
    }
}

/* Know More Section */
.knowMore-bg {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
    opacity: 1;
    animation: fadeIn 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.knowMore-container {
    width: 55%;
    height: fit-content;
    padding: 4rem 3rem;
    border-radius: 0.7rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    position: relative;
    z-index: 100;
}

.knowMore-img {
    width: 15rem;
}
.knowMore-right {
    margin-left: 1rem;
    width: 60%;
}
.knowMore-title {
    font-size: 1.5rem;
    margin-top: 0;
    background-color: #f3eec2;
    padding: 0.5rem;
    width: fit-content;
    border-radius: 0.4rem;
}
.knowMore-p {
    font-size: 1.1rem;
    background-color: #f5f1d0;
    padding: 0.5rem;
    border-radius: 0.4rem;
    text-align: justify;
}